import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.css';
import SurveyVerginia from './survey/SurveyVerginia.jsx';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import SurveyAdm from './views/SurveyAdm.jsx';
import SurveyView from './survey/SurveyView.jsx';
import SurveyEditor from './survey/SurveyEditor.jsx';
import SurveyCreate from './survey/SurveyCreate.jsx';
import ThankYouPage from './components/ThankYouPage';
import Main from './views/Main';
import AuthProvider, { AuthContext } from './components/Auth/AuthContext';
import Login from './components/Auth/Login';
import RequireAuth from './components/Auth/RequireAuth';
import Chatbot from './chatbot/Chatbot';
import SourceSkillsResult from './source_skills/SourceSkillsResult';
import SourceSkillsResultAi from './source_skills/SourceSkillsResultAi';
import SourceSkillsGroupResult from './source_skills/SourceSkillsGroupResult';

const App = () => {
  const auth = useContext(AuthContext);
  if (!auth) return null;

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SurveyView id_survey={1} />} />
          {/* <Route path="/Verginia2023" element={<SurveyView id_survey={2} />} />
          <Route path="/Verginia2023/Obrigado" element={<ThankYouPage id_survey={2} />} /> 
          <Route path="/Verginia2023Organizacao" element={<SurveyView id_survey={10} />} />
          <Route path="/Verginia2023Organizacao/Obrigado" element={<ThankYouPage id_survey={2} />} /> */}
          <Route path="/Report" element={<SourceSkillsResult />} />
          <Route path="/ReportAI" element={<SourceSkillsResultAi />} />
          <Route path="/GroupReport" element={<SourceSkillsGroupResult />} />
          <Route path='/Chatbot' element={<Chatbot />} />
          <Route path='/login' element={<Login />} />
          <Route path="/Admin/*" element={<RequireAuth><SurveyAdm /></RequireAuth>} />
          <Route path="/Survey/View/:id_survey" element={<RequireAuth><SurveyView /></RequireAuth>} />
          <Route path="/Survey/View/:id_survey/Obrigado" element={<RequireAuth><ThankYouPage /></RequireAuth>} />
          <Route path="/Survey/Editor/:id_survey" element={<RequireAuth><SurveyEditor /></RequireAuth>} />
          <Route path="/Survey/Create" element={<RequireAuth><SurveyCreate /></RequireAuth>} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const AppWrapper = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWrapper />);

reportWebVitals();
