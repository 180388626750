import { useState, useEffect, useCallback } from "react";
import uuidv4 from "../Utils";
import { retry, saveData, sendEmail } from './dataService.js';

export default function useSurvey(id_survey, navigate) {
    const urlParams = new URLSearchParams(window.location.search);
    const app = 'lime_pi_dev_v1';

    const [json, setJson] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [guid, setGuid] = useState(uuidv4());
    const [token, setToken] = useState(urlParams.get('token'));
    const [startDate, setStartDate] = useState(null);
    const [data_saved, setData_saved] = useState(false);
    const [interaction_saved, setInteraction_saved] = useState(false);
    const [email_sent, setEmail_sent] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    //const [interactions, setInteractions] = useState([]);
    var interactions = []
    const [data, setData] = useState(null); // added data state

    const handleValueChanged = async (sender, options) => {
        options.timelapsed_ms = Date.now() - startDate;
        options.interactionType = 'value_changed'

        //setInteractions(prevInteractions => [...prevInteractions, options]);
        interactions.push(options)
        console.log('value_changed')
        console.log(options)
    };

    const handlePageChanged = (sender, options) => {
        let page_changed = {
            interactionType: 'page_changed',
            timelapsed_ms: Date.now() - startDate,
            isNextPage: options.isNextPage,
            isPrevPage: options.isPrevPage,
            oldCurrentPage: options.oldCurrentPage.name,
            newCurrentPage: options.newCurrentPage.name
        };
        //setInteractions(prevInteractions => [...prevInteractions, page_changed]);
        interactions.push(page_changed)
        // let data = { interactions: interactions }
        // data.guid_answer = guid;
        // data.id_survey = id_survey;
        // data.token = token || guid;
        // data.detailed = true;
        // data.app = app;
        // if (interactions.length > 0) {
        // retry(() => saveData(guid, data, true), 3, 5000)
        //     .then(() => {
        //     setInteraction_saved(true)
        //     console.log('interactions saved')
        //     }
        //     )
        //     .catch(err => setError(err.message));
        // }
        console.log('page_changed')
    };

    const handleComplete = useCallback(async (sender,options) => {
        let data = sender.data;
        data.guid_answer = guid;
        data.id_survey = id_survey;
        data.token = data.token || token || guid;
        data.detailed = false;
        data.app = app;
        data.iso_start_date = new Date(startDate).toISOString();
        data.iso_end_date = new Date(Date.now()).toISOString();
        data.timelapsed_ms = Date.now() - startDate;
        data.timelapsed_secs = (Date.now() - startDate) / 1000;
        data.interactions = interactions;
        //setData(data); // store data in state
        //saveData(guid, data, false)

        
        options.showSaveInProgress();

        await retry(async () => await saveData(guid, data, false), 3, 5000)
            .then(() => {
                localStorage.removeItem('saveData');
                setData_saved(true);

            })
            .catch(err => {
                localStorage.setItem('saveData', JSON.stringify(data)); // save data locally
                console.log('Data saved locally');
                setError(err.message);
                options.showSaveError();
                setLoading(false);
                setIsModalOpen(true);
                
            });
        

        if(data_saved) {
            options.showSaveSuccess();
        }
        
        console.log('complete')
    });
    useEffect(() => {
        console.log('data_saved: ' + data_saved)
        if (data_saved) {
            if (id_survey == 1) {
            sendEmail(guid, data)
                .then(() => setEmail_sent(true))
                .catch(err => setError(err.message));

            navigate('/ReportAi?guid=' + guid);
            }
            else {
                navigate(`${window.location.pathname}/Obrigado`);
            }
            
        }
    }, [data_saved]);

    useEffect(() => {
        if (!data_saved && localStorage.getItem('saveData')) {
            console.log('Found local data, saving...')
            setError("O formulário anterior não foi salvo corretamente. Por favor aguarde enquanto tentamos salvar novamente.");
            setIsModalOpen(true);
            const localStorageData = JSON.parse(localStorage.getItem('saveData'));
            console.log(localStorageData)
            console.log('retrying save...') 
            retry(() => saveData(guid, localStorageData, false), 3, 5000)
                .then(() => {
                    localStorage.removeItem('saveData'); // remove data from local storage if it's saved
                    setIsModalOpen(false);
                    setData_saved(true);
                })
                .catch(err => {
                    setError(err.message) 
                });
        }
        console.log('useEffect localStorageData')
    }, [guid, data_saved]);

    useEffect(() => {
        console.log('loading data...')
        const getData = async () => {
            try {
                const url_fetch = `${process.env.REACT_APP_LIME_API_URL}/survey/${id_survey}`;
                console.log(url_fetch);
                const response = await fetch(url_fetch);
                if (!response.ok) {
                    throw new Error(`HTTP error: status ${response.status}`);
                }
                let actualData = await response.json();
                document.title = 'Lime PI | ' + actualData.name;
                setJson(actualData.survey_json);
                setStartDate(Date.now());
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            };
        }
        getData().catch(err => setError(err.message));
    }, [id_survey]);

    return { json, loading, error, isModalOpen, handleValueChanged, handlePageChanged, handleComplete, setIsModalOpen };
}
